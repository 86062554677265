import psychologyClient from '@/axiosClients/psychologyClient';

const getAvailableDates = (data) => {
    return psychologyClient.get(`date-availables/${data.id}/${data.startDate}/${data.endDate}?timezone=${data.timezone}`)
}
const getMachingV2 = (data) => {
    return psychologyClient.get(`matching/subsequent-quote?timezone=${data.timezone}${data.patientId ? '&patient_id=' + data.patientId : ''}`);
}

const getPsychologistById = (id) => {
    return psychologyClient.get(`${id}`);
}

const saveInterested = (data) => {
    return psychologyClient.post(`interested-psychologits`, data);
}

const saveClientComments = (data) => {
    return psychologyClient.post(`client-comments`, data);
}

export default {
    getAvailableDates,
    getMachingV2,
    getPsychologistById,
    saveInterested,
    saveClientComments
};