import patientClient from '@/axiosClients/patientClient';

 
const getPatientById = (id) => patientClient.get(`/patient/${id}`);

const acceptInformedConsent = (patientId) => {
    return patientClient.post(`/patient/accept-informed-consent/${patientId}`)
}
const updateContactInformation = (info) => {
    return patientClient.post(`/update-contact-information`, info);
}

export default {
    getPatientById,
    acceptInformedConsent,
    updateContactInformation
}