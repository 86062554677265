import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
  name: "admin-reports",
  component: () => import(/* webpackChunkName: "auth-layout" */ "@/layouts/PrincipalLayout.vue"),
  children: [
    {
      path: "evaluations",
      name: "admin-evaluations-reports",
      beforeEnter: [isVerifyTokenGuard],
      meta: {
        breadcrumbs: [
          {
            transKey: 'breadcrumbs.admin-evaluation-reports',
            isActive: true
          }
        ]
      },
      component: () => import(/* webpackChunkName: "admin-evaluations-reports" */ "@/modules/admin/reports/views/Evaluations.vue"),
    }
  ]
};
