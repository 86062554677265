import { createRouter, createWebHistory } from 'vue-router';
import authRouter from '@/modules/principal/router';
import psycologistsOptionsRouter from '@/modules/psycologists-options/router';
import psycologistsConfigurationsRouter from '@/modules/psycologists/configuration/router';
import psycologistsPatientsRouter from '@/modules/psycologists/patients/router';
import usersRouter from '@/modules/users/router';
import interviewerRouter from '@/modules/interviewer/router';
import schedulerRouter from '@/modules/appointments/router';
import patientsRouter from '@/modules/patients/router';
import profilePatientRouter from '@/modules/patients/profile/router';
import profilePsycologistsRouter from '@/modules/psycologists/profile/router';
import waitingRoomRouter from '@/modules/waiting-room/router';
import videocallRouter from '@/modules/videocall/router';
import privacyRouter from '@/modules/privacy/router';
import psychologistInvoicingRouter from '@/modules/psycologists/invoicing/router';
import adminInvoicingRouter from '@/modules/admin/invoicing/router';
import adminCustomersRouter from '@/modules/admin/customers/router';
import adminSessionsRouter from '@/modules/admin/sessions/router';
import adminDiscountCodesRouter from '@/modules/admin/discount-codes/router';
import adminCardsMimenteRouter from '@/modules/admin/cards-mimente/router';
import reportsRouter from '@/modules/admin/reports/router';
import scalesRouter from '@/modules/questionnario-scales/router';
const routes = [
  {
    path: "/",
    ...authRouter,
  },
  {
    path: "/patients",
    ...patientsRouter,
  },
  {
    path: "/psycologists",
    ...psycologistsOptionsRouter,
  },
  {
    path: "/psycologists/configurations",
    ...psycologistsConfigurationsRouter,
  },
  {
    path: "/psycologists/patients",
    ...psycologistsPatientsRouter,
  },
  {
    path: "/f-users",
    ...usersRouter,
  },
  {
    path: "/interviewer",
    ...interviewerRouter,
  },
  {
    path: "/schedule-appointment",
    ...schedulerRouter,
  },
  {
    path: "/profile-patient",
    ...profilePatientRouter,
  },
  {
    path: "/profile-psychologist",
    ...profilePsycologistsRouter,
  },
  {
    path: "/waiting-room",
    ...waitingRoomRouter
  },
  {
    path: '/video',
    ...videocallRouter
  },
  {
    path: '/privacy',
    ...privacyRouter
  },
  {
    path: '/psycologists/invoicing',
    ...psychologistInvoicingRouter
  },
  {
    path: '/admin/invoicing',
    ...adminInvoicingRouter
  },
  {
    path: '/admin/customers',
    ...adminCustomersRouter
  },
  {
    path: '/admin/sessions',
    ...adminSessionsRouter
  },
  {
    path: '/admin/discount-codes',
    ...adminDiscountCodesRouter
  },
  {
    path: '/admin/business-card',
    ...adminCardsMimenteRouter
  },
  {
    path: '/admin/reports',
    ...reportsRouter
  },
  {
    path: '/scales',
    ...scalesRouter
  }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        const scroll = document.getElementById('main_content')
        if (scroll) {
            scroll.scrollTop = 0
        }
        return {
            top: 0
        }
    }
})

export default router
